export default {
    setUser(state, user) {
      state.user = user
    },
    setSelectedOrgan(state, organSelected) {
      state.organSelected = organSelected
    },
    setWelcomeDesenreda(state, welcomeDesenreda) {
      state.welcomeDesenreda = welcomeDesenreda
    },
    setSensacionesSeleccionadas(state, sensacionesSeleccionadas){
      state.sensacionesSeleccionadas = sensacionesSeleccionadas
    }
  };
  