import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Carousel3d from 'vue-carousel-3d';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'animate.css'
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
// import VueTypedJs from 'vue-typed-js'

Vue.config.productionTip = false

Vue.use(VueSweetalert2)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Carousel3d);
// Vue.use(VueTypedJs)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
