export default {
    getUser(state) {
      return state.user;
    },
    getSelectedOrgan(state) {
      return state.organSelected;
    },
    getWelcomeDesenreda(state) {
      return state.welcomeDesenreda
    },
    getSensacionesSeleccionadas(state){
      return state.sensacionesSeleccionadas
    }
  };